import { fb, validators } from '../../../lib/form';

export function getContactModel(data) {
  
  const storeList = [
    {name : 'Store Location (Please select)*', value : ''},
    {name : 'Mile End', value : 'Mile End'},
    {name : 'Blair Athol', value : 'Blair Athol'},
    {name : 'Elizabeth', value : 'Elizabeth'},
    {name : 'Hillcrest', value : 'Hillcrest'},
  ];
  
  const model = fb.group({
    firstName: [data.name || '', [validators.Required()], { label: 'First Name', type: 'text' }],
    lastName: [data.name || '', [validators.Required()], { label: 'Last Name', type: 'text' }],
    phone: [data.phone || '', [validators.Required()], { label: 'Phone', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    store: [data.store || '', [validators.Required()], { label: null, type: 'select', options: storeList }],
    //enquiry: [data.enquiry || '', [validators.Required()], { label: 'Your Message', type: 'textarea' }],
    attachments: [data.attachments || '', [], { label: 'Attachments', type: 'file' }]
  }); 
  return model;
}