/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment, useState } from "react";
//import style from './shippingStyle';
import { Button, ErrorMessage } from "../../../components";
import { CartSummary } from "../cartSummary/summary";
import utils from "../../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function Shipping({
  cart,
  form,
  saveShipping,
  post,
  pickupDate,
  setPickupDateFunction
}) {
  const model = form.model;
  const render = (name) => form.renderControl(name);
  
  const [checkPickupDate, setCheckPickupDate] = useState();

  const val = form.getValue();
  const billingInfo = model.find("billingInfo");
  const shippingInfo = model.find("shippingInfo");
  const pickupTimeDateMsgOn =
    post.errors.toString() !== "" &&
    post.errors.toString().includes("Pickup Date")
      ? true
      : false;

  const next = (e) => { 
    
    setCheckPickupDate(true);
    if(!pickupDate || pickupDate === ''){
      setCheckPickupDate(false);
    }

    form.validateForm(e, () => {  
      if(!pickupDate || pickupDate === ''){
        return;
      } 
      saveShipping();
      utils.ui.scrollTo("#ecmcart");
    });
  };

  if (billingInfo) {
    billingInfo.disableValidators = val.useSameAddress;
  }

  shippingInfo.disableValidators = false;

  if (val.shippingMethod === "Pickup" || val.shippingMethod === "0") {
    shippingInfo.disableValidators = true;
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <form className="cart-shipping">
            <h3>Delivery Method</h3>
            {render("shippingMethod")}
            {val.shippingMethod !== "" && (
              <Fragment>
                {val.shippingMethod === "Standard" && (
                  <Fragment>
                    {/* <h3>Shipping Country</h3>
                  {render('shippingCountry')} */}
                    <h3>Shipping Address</h3>
                    <Address form={form} addressModel={shippingInfo} />
                  </Fragment>
                )}

                <h3>Location</h3>

                <div className="row">
                  <div className="col-12">{render("location")}</div>
                </div>

                <h3>Pickup Time</h3>
                <div className="row">
                  <div className="col-12 col-md-6">
                    {/* {render("pickupDate")} */}
                    <DatePicker
                      className="form-control"
                      selected={pickupDate}
                      //onChange={(date) => setPickupDate(date)}
                      onChange={(date) => setPickupDateFunction(date)}
                      placeholderText={"dd/mm/yyyy"}
                      dateFormat="dd/MM/yyyy"
                      minDate = {new Date((cart.availableFirstDate.split("T"))[0])}
                      excludeDates={[new Date("2024/12/25"), new Date("2024/12/26")]}
                    />
                    {
                      checkPickupDate === false && (!pickupDate || pickupDate === '') &&
                      <div className="invalid" style={{marginBottom : '0.5rem'}}>Pickup Date is required.</div>
                    } 
                  </div>
                  <div className="col-12 col-md-6">{render("pickupTime")}</div>
                  <div className="col-12 pickup-time-date-msg">
                    <div className="pickup-time-date-msg-inner">
                      <div
                        className={`error-msg ${
                          pickupTimeDateMsgOn ? "on" : "off"
                        }`}
                      >
                        Pickup Date is not valid.
                      </div>
                      <div>
                        The following dates/times are not available. <br />
                        Between 4PM on the 24th and 11AM on the 27th Dec <br />
                        Between 4PM on the 31st and Midday on the 1st Jan
                      </div>
                    </div>
                  </div>
                </div>
                <h3>Billing Address</h3>
                {val.shippingMethod === "Standard" && (
                  <Fragment>{render("useSameAddress")}</Fragment>
                )}
                {!val.useSameAddress && (
                  <Address form={form} addressModel={billingInfo} />
                )}
              </Fragment>
            )}
          </form>
        </div>

        <div className="col-md-3">
          <CartSummary cart={cart} />
        </div>
      </div>

      {val.shippingMethod !== "" && (
        <Fragment>
          <div className="form__actions">
            <Button onClick={next} status={form.status}>
              Next
            </Button>
            <ErrorMessage
              errors={form.errors}
              summaryMessage="Please review the errors."
            />
            <ErrorMessage
              errors={(checkPickupDate === false && (form.errors === undefined || form.errors.length === 0)) ? ["errors"] : null}
              summaryMessage="Please review the errors."
            />
            <ErrorMessage errors={post.errors} />
          </div>
        </Fragment>
      )}
    </div>
  );
}

function Address({ form, addressModel }) {
  const render = (name) => form.renderControl(name, addressModel);
  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6">{render("firstName")}</div>
        <div className="col-12 col-md-6">{render("surname")}</div>
      </div>
      <div className="row">
        <div className="col-12">
          {render("street1")}
          {render("street2")}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">{render("suburb")}</div>
        <div className="col-12 col-md-4">{render("state")}</div>
        <div className="col-12 col-md-4">{render("postcode")}</div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">{render("email")}</div>
        <div className="col-12 col-md-6">{render("mobile")}</div>
      </div>
    </div>
  );
}
