import { fb, validators } from '../../../form';
//import utils from '../../../utils';

export function getShippingFormData(cart) {
  const data = cart || {};

  

  const shippingMethods = [
    // { name: 'Shipping', value: "Standard" },
    { name: 'Pickup', value: "Pickup" }
  ];

  const shippingCountry = [
    { name: 'Australia', value: "Australia" },
    { name: 'International', value: "International" }
  ];

  const locationList = [
    { name: 'Mile End', value: "mile_end" },
    { name: 'Blair Athol', value: "blair_athol" },
    { name: 'Elizabeth', value: "elizabeth" },
    { name: 'Hillcrest', value: "hillcrest" }
  ];

  //const availableFirstDate = utils.date.formatYYYYMMDD(data.availableFirstDate);
  //const pickupDate = data.orderExtSerializedObj && data.orderExtSerializedObj.pickupDate ? data.orderExtSerializedObj.pickupDate : null;
  const pickupTime = data.orderExtSerializedObj && data.orderExtSerializedObj.pickupTime ? data.orderExtSerializedObj.pickupTime : null;
  const location = data.orderExtSerializedObj && data.orderExtSerializedObj.location ? data.orderExtSerializedObj.location : null;

  let pickupTimeList = [
    '12:00 AM',
    '12:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '04:30 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
  ].map(x => { return { name: x, value: x } })
  pickupTimeList = [{name: 'Select Time', value: ''}, ...pickupTimeList]

  const model = fb.group({
    billingInfo: address(data.billingAddress),
    shippingInfo: address(data.shippingAddress),
    //shippingMethod: [data.shippingMethod || '', [], { label: 'Shipping Method', type: 'text' }],
    //shippingMethod: [data.shippingMethod || '', [], { label: null, type: 'radioList', options : shippingMethods, style : {inline : true} }],
    shippingMethod: ['Pickup', [], { label: null, type: 'radioList', options : shippingMethods, style : {inline : true} }],
    shippingCountry: [data.shippingCountry || 'Australia', [], { label: null, type: 'radioList', options : shippingCountry, style : {inline : true} }],
    //useSameAddress: [data.useSameAddress || false, [], { label: `Use the same address`, type: 'checkbox' }],
    useSameAddress: [false, [], { label: `Use the same address`, type: 'checkbox' }],
    //pickupDate : [pickupDate || '', [validators.Required()], { label: null, type: 'date', attrs: { min: availableFirstDate } }],
    pickupTime: [pickupTime || '', [validators.Required()], { label: null, type: 'select', options: pickupTimeList }],
    location: [location || 'mile_end', [validators.Required()], { label: null, type: 'select', options: locationList }]
  });
  return model;
}

export function address(model) {
  const data = model || {}
  return fb.group({
    firstName: [data.firstName || '', [validators.Required()], { label: 'First Name', type: 'text', attrs: { autoComplete : 'first-name' } }],
    surname: [data.surname || '', [validators.Required()], { label: 'Surname', type: 'text', attrs: { autoComplete : 'sur-name' } }],
    street1: [data.street1 || '', [validators.Required()], { label: 'Street', type: 'text', attrs: { autoComplete : 'street' }  }],
    street2: [data.street2 || '', [], { label: null, type: 'text' }],
    suburb: [data.suburb || '', [validators.Required()], { label: 'Suburb', type: 'text', attrs: { autoComplete : 'suburb' } }],
    state: [data.state || '', [validators.Required()], { label: 'State', type: 'text', attrs: { autoComplete : 'state' } }],
    postcode: [data.postcode || '', [validators.Required()], { label: 'Postcode', type: 'text', attrs: { autoComplete : 'state' } }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email Address', type: 'email', attrs: { autoComplete : 'email' } }],
    mobile: [data.mobile || '', [validators.Required()], { label: 'Mobile', type: 'tel', attrs: { autoComplete : 'mobile' } }],
  })
}

// public AddressModel ShippingInfo { get; set; }
// public AddressModel BillingInfo { get; set; }
// public string ShippingMethod { get; set; }
// public bool UseSameAddress { get; set; }
// public string SpecialRequest { get; set; }

// public string FirstName { get; set; }
// public string Surname { get; set; }
// public string AddressLine { get; set; }
// public string Suburb { get; set; }
// public string State { get; set; }
// public string Postcode { get; set; }
// public string Country { get; set; }
// public string CountryCode { get; set; }
// public string Email { get; set; }
// public string Phone { get; set; }
// public string Mobile { get; set; }