/** @jsx jsx */
import { jsx } from "@emotion/core";
//import React from 'react';
import cmsUtils from "../../cms/utils/cmsUtils";
import utils from "../../utils";
import style from "./style";
import { Item } from "../../cms/items/item";

export function ExpandablePanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  const bgColor = cmsUtils.payload(item, "BgColor");
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");

  let bgUrl = null;

  if (imageUrl) {
    bgUrl = utils.site.resourcePath(imageUrl);
  } else if (backgroundTile) {
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );
  }

  const cssClass = utils.classNames(
    "cms_item",
    "contentPanel",
    "expandablePanel",
    cmsOption.isCmsEdit && "expandablePanel_item_cms",
    item.cssClass || item.anchorName || ""
  );
  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));

  //id={item.anchorName}
  return (
    <section
      className={cssClass}
      style={{
        backgroundColor: `${bgColor}`,
        backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
      }}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      css={!cmsOption.isCmsEdit && style.exTextItem}
      data-anchor-status='collapse'
      data-cms-item-anchor={item.anchorName}
      data-cms-anchor={item.anchorName}
    >
      <div className='contentPanel__bg'></div>
      <div className='container'>{subItemsElts}</div>
    </section>
  );
}
