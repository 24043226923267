//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles : css(mq({
         display: 'flex',
         justifyContent: 'flex-start',
         flexWrap: 'wrap',
         margin: '2rem -3rem',
         marginLeft: ['0px', null,  '-1rem', '-1rem', '-2rem', '-3rem'],
         marginRight: ['0px', null,  '-1rem', '-1rem', '-2rem', '-3rem'],
         overflow:'hidden',
         paddingTop:'2rem',
         paddingBottom:'2rem'
    })),
    pageTile__tile : css(mq({
         flex: '0 0 100%',
         maxWidth: '100%',
         padding: '10px',
         paddingTop:'1.5rem',
         paddingBottom: '1.5rem',
         zIndex:1,
         '&:nth-of-type(even)' : {
               '.pageTile__bsyc__bg' : mq({
                    order: ['0',null,'2'],
               }),
               '.pageTile__bsyc__content__details' : mq({
                    order: '1'
               })
         }
    })),

    tile__content : css({
     //     display: 'flex',
     //     flexDirection: 'row',
     //     justifyContent: 'space-between',
     //     width: '100%',
     //     height: '100%',
     //     cursor: 'pointer',
     //     position: 'relative',
     //     flexWrap: 'wrap',
         '&:hover' : {
            textDecoration: 'none',
            '& .pageTile__bsyc__content__details' : {
               color: 'white',
               //backgroundColor: 'rgba(170, 150, 119,1)'
               // '&.default' : css({
               //      backgroundColor: 'rgba(0,113,188,1)'
               // }),
               // '&.red' : css({
               //      backgroundColor: 'rgba(211,61,2,1)'
               // }),
               // '&.green' : css({
               //      backgroundColor: 'rgba(0, 146, 69,1)'
               // })
            },
            '& .pageTile__bsyc__bg2' : {
               //transform: scale(1.05)
               transform : 'scale(1.05)'
            }
         }
    }),

    tile__flip__wrapper : css({
          zIndex:'-1', 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          position: 'relative',
          flexWrap: 'wrap',
          backgroundColor: 'rgba(255, 255, 255, 0.4)'
    }),

//     tile__bg : css(mq({
//          height: '0',
//          //paddingTop: '30%',
//          paddingTop: ['50%','35%','30%'],
//          backgroundPosition: '50%',
//          backgroundRepeat: 'no-repeat',
//          backgroundSize: 'cover',
//          flex: '1 1 300px'
//     })),

tile__bg : css(mq({
     height: '0',
     paddingTop: ['55%', '42%','25%'],
     //height: ['50%','35%','30%'],
     //flex: '1 1 300px',
     flex: '1 1 400px',
     //overflow:'hidden',
     position:'relative'
})),

tile__bg2 : css(mq({
    height: ['auto', null, '100%'],
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    //overflow:'hidden',
    transition:'all .2s',
    position:'absolute',
    top:'0px',
    width:'100%',

})),
img__container : mq({
    width:['372px', null, '360px', '470px', '500px', '600px'],
    position:'absolute',
    left: ['50%', null, '30px'],
    //left: ['50%', null, '68px'],
    top: ['-20px', null, '50%'],
    transform:['translateX(-50%)', null, 'translateY(-50%)'],
    // '&.even' : mq({
    //     left: '0px',
    //     right: ['50%', null, '70px']
    // })
}),

    tile__details : css(mq({
         overflow: 'hidden',
         flexGrow: '1',
         //textAlign: 'center',
         width: '100%',
         bottom: '0',
         //backgroundColor: variables.primaryColor,
         //backgroundColor: '#ebebeb',
     //     '&.default' : css({
     //           backgroundColor: 'rgba(0, 113, 188,.5)'
     //     }),
     //     '&.red' : css({
     //           backgroundColor: 'rgba(211, 61, 2,.5)'
     //      }),
     //      '&.green' : css({
     //           backgroundColor: 'rgba(0, 146, 69,.5)'
     //      }),
         //backgroundColor : '#77cf22',
         flex: '1 1 300px',
         transition: 'all .2s ease-in-out',
         //color: '#ebebeb',
         position:'relative',
         paddingTop:['2.5rem', null, '0px'],
         '& .pageTile__bsyc__container' : mq({
            //position: 'absolute',
            position: ['static,',null,'absolute'],
            top: '50%',
            left:'50%',
            transform: ['none',null,'translate(-50%, -50%)'],
            width:['100%',null,'90%'],
            padding:['1rem',null,'0px'],
            '& .pageTile__bsyc__title' : {
                //fontFamily: 'proxima-soft, sans-serif',
                fontSize: '1.8rem',
                //textTransform: 'uppercase',
                //fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                paddingTop: '0.3rem',
                paddingBottom: '0.3rem',
                color:variables.dkBlueColor,
                fontWeight: 700,
                '&.even' : mq({
                    paddingLeft: ['0px', null, '50px']
                })
            },
            '& .pageTile__bsyc__short__des' : {
                color:variables.dkBlueColor,
                paddingBottom: '0.3rem',
                '&.even' : {
                    paddingLeft: '50px'
                }
             },
            '& .pageTile__bsyc__desc' : mq({
                lineHeight: '1.3',
                fontSize: '0.81rem',
                //color: '#3d3d3d',
                minHeight: '70px',
                color:variables.fontColor,
                paddingRight: ['0px', null, '1.8rem'],
                '&.even' : mq({
                    paddingLeft: ['0px', null, '50px']
                })
             })
         })
    }))
}