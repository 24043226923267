import React, { useRef, useState, useEffect } from "react";
import cmsUtils from "../../cmsUtils";
import utils from "../../../../utils";
import { useRouter } from "../../../../components";
import { handleElementLink } from "../../../../components";
import env from "../../../../env";
import { renderHtml } from "./renderHtml";
import { createPortal } from "react-dom";
import { Item } from "../../../../cms/items/item";
import { AiOutlineCloseCircle } from "react-icons/ai";

//import { Document, Page } from 'react-pdf'
//import { Document, Page } from 'react-pdf/dist/entry.webpack';

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function Html(props) {
  const ver = env.ver;
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const { history } = useRouter();
  const [openPopup, setOpenPopup] = useState(false);
  const [popupPageUrl, setPopupPageUrl] = useState(null);
  const [popupPageData, setPopupPageData] = useState(null);

  //const [ pdfViewPath, setPdfViewPath ] = React.useState(null)
  //console.log('item', item, cmsOption);

  const year = new Date().getFullYear();
  let content = cmsOption.isCmsEdit
    ? cmsUtils.payload(item, "Content")
    : cmsUtils
        .payload(item, "Content")
        .replace(/__year__/g, year)
        .replace(
          "__version__",
          "<a href = '/app' class = 'app_version'> PWA " + ver + "</a>"
        );

  const cssClass = utils.classNames(
    "cms_item",
    "htmlPanel",
    cmsOption.isCmsEdit && "html",
    item.cssClass || item.anchorName || ""
  );

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  function handleClick(e) {
    let target = "";
    let href = "";
    if (e.target.closest("a")) {
      target = e.target.closest("a").getAttribute("target");
      href = e.target.closest("a").getAttribute("href") + "";
    }

    if (href.includes("/popup-page/")) {
      e.preventDefault();
      e.stopPropagation();

      openPopUpFunction(href);
      return;
    }

    const processed =
      target === "_blank"
        ? handleElementLink(e.target, null)
        : handleElementLink(e.target);
    //const link = utils.url.getLinkFromElement(e.target);
    //console.log('clicked', e, e.target, processed);

    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (processed.linkInfo && processed.linkInfo.type === "internal") {
      e.preventDefault();
      e.stopPropagation();
      history.push(processed.linkInfo.url);
    } else {
      //e.preventDefault();
      //e.stopPropagation();
      // setPdfViewPath(utils.site.resourcePath(link))

      // if (e.target.closest('a')) {
      //   let href = e.target.closest('a').getAttribute('href') + "";
      //   if (href.indexOf('#') !== -1) {
      //     let _href = href.split('#');
      //     console.log("_href", _href);
      //     let el = document.querySelector('section[data-cms-item-anchor="' + _href[1] + '"]');
      //     if (el) {
      //       let anchorStatus = el.getAttribute('data-anchor-status');
      //       if (anchorStatus === "collapse") {
      //         el.setAttribute('data-anchor-status', 'expand');
      //         el.classList.add("expand");
      //       }
      //       else {
      //         el.setAttribute('data-anchor-status', 'collapse');
      //         el.classList.remove("expand");
      //       }
      //     }
      //     e.preventDefault();
      //     e.stopPropagation();
      //   }
      // }
      // process expandable
      if (e.target.closest("a")) {
        let aLink = e.target.closest("a");
        let href = aLink.getAttribute("href") + "";
        if (href.indexOf("#") !== -1) {
          let _href = href.split("#");
          console.log("_href", _href);
          const targetId = _href[1];
          let targetElt = document.querySelector(
            'section[data-cms-item-anchor="' + _href[1] + '"]'
          );

          const runEffect = () => {
            let anchorStatus = targetElt.getAttribute("data-anchor-status");
            if (anchorStatus === "collapse") {
              targetElt.setAttribute("data-anchor-status", "expand");
              targetElt.classList.add("expand");
            } else {
              targetElt.setAttribute("data-anchor-status", "collapse");
              targetElt.classList.remove("expand");
            }
          };

          if (targetElt) {
            const wrapper = aLink.closest("p, div, h1, h2, h3, h4, h5");
            const nextElt = wrapper.nextElementSibling;
            //console.log(wrapper, nextElt)

            if (
              nextElt &&
              nextElt.getAttribute("data-cms-item-anchor") === targetId
            ) {
              // already in wrapper
            } else {
              // no wrapper
              wrapper.insertAdjacentElement("afterend", targetElt);
            }

            window.setTimeout(runEffect, 100);

            e.preventDefault();
            e.stopPropagation();
          }
        }
      }
    }
  }

  function openPopUpFunction(url) {
    setOpenPopup(true);
    setPopupPageUrl(url);
    setPopupPageData(null);
    document.body.style.overflow = "hidden";
  }

  function closePopUpFunction() {
    setOpenPopup(false);
    setPopupPageUrl(null);
    document.body.style.overflow = "unset";
  }

  const htmlBlock = useRef(null);

  useEffect(() => {
    if (!popupPageUrl) {
      return;
    }
    const fetchData = async () => {
      const res = await fetch(
        env.apiBase + "/api/cms/getpublishedpopuppage?path=" + popupPageUrl
      );
      const data = await res.json();
      console.log("data ---->>>> ", data);
      setPopupPageData(data.data);
    };

    fetchData();

    // eslint-disable-next-line
  }, [popupPageUrl]);

  return (
    <>
      {openPopup && (
        <Portal>
          <div
            className='cms-html-itme-portal-back-ground'
            onClick={closePopUpFunction}
          ></div>
          {
            <div className='cmsPage-popup-page-container'>
              <div className='cms-html-itme-portal-close-btn'>
                <AiOutlineCloseCircle onClick={closePopUpFunction} />
              </div>
              {popupPageData ? (
                <div
                  className={`cmsPage cmsPage--${popupPageData.pageTypeCode}`}
                >
                  {popupPageData.sections.map((section, index) => (
                    <Section
                      item={section}
                      key={section.sectionName + index}
                      cmsOption={{}}
                    ></Section>
                  ))}
                </div>
              ) : (
                <div className='cmsPage-popup-error'>
                  {" "}
                  This page is not exist{" "}
                </div>
              )}
            </div>
          }
        </Portal>
      )}
      <div
        ref={htmlBlock}
        className={cssClass}
        data-cms-html='true'
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        onClick={(e) => handleClick(e)}
      >
        {renderHtml(content, cmsOption)}
      </div>
    </>
  );
}

function Section(props) {
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0) return <></>;

  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));
  return <>{subItemsElts}</>;
}
