const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  dkGrayColor : '#444444',
  dkBlueColor : '#222f67',
  lightGoldColor : '#ded7c2',
  goldColor : '#aa9964',
  dkGoldColor : '#948863'
}

const variables = {
  familyBase: '"raleway",sans-serif',
  familyHeader: '"raleway",serif',

  primaryColor: colors.goldColor,
  fontColor: colors.dkGrayColor,
  bgColor: colors.lightGoldColor,

  ...colors,
  ...overloading
}

export default variables;