// xs,  sm,  md,  lg,  xl,  xxl     <-- only sample
//import { mq } from "../../../../cssInJs"
import { variables } from 'cssInJs';

// <    576  768  992  1200 1600
export function getPanelStyle() {
  return {
    goldBar : {
      width: '100%',
      paddingTop: '0.5rem',
      backgroundColor: variables.goldColor,
      position: 'absolute',
      '&.gold-bar-top' : {
        top:'0'
      },
      '&.gold-bar-bottom' : {
        bottom: '0'
      }
    }
  }
}