import env from "../../../env";

export function initStripe(setError, location = "mile_end") {
  // Create a Stripe client.

  let _stripeKey = env.stripeKey;

  switch (location) {
    case "mile_end":
      _stripeKey = env.stripeKey;
      break;
    case "blair_athol":
      _stripeKey = env.stripeKey2;
      break;
    case "elizabeth":
      _stripeKey = env.stripeKey3;
      break;
    case "hillcrest":
      _stripeKey = env.stripeKey4;
      break;
    default:
      break;
  }

  console.log("_stripeKey ____", location, _stripeKey);

  const stripe = window.Stripe(_stripeKey);

  // Create an instance of Elements.
  const elements = stripe.elements();

  // Custom styling can be passed to options when creating an Element.
  // (Note that this demo uses a wider set of styles than the guide below.)
  const elStyle = {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        //color: '#aab7c4'
        color: "#000000",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  // Create an instance of the card Element.
  const card = elements.create("card", { style: elStyle });

  // Add an instance of the card Element into the `card-element` <div>.
  card.mount("#card-element");

  // Handle real-time validation errors from the card Element.
  card.addEventListener("change", function (event) {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  });

  return { stripe, card };
}
